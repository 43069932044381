import React, { FC, useEffect } from 'react';
import { Props, Styles } from 'react-modal';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Modal, Text } from 'components';
import PaymentDialogOrderSummary from './PaymentDialogOrderSummary';
import PaymentsForm from './PaymentsForm';
import EmailInput from './EmailInput';
import { AppState } from 'state/types';
import PaymentSteps from './PaymentSteps';
import SecureFooter from 'assets/icons/payments/secure-footer.svg';
import ExcludeIcon from 'assets/icons/lock-solid.svg';
import DolorIcon from 'assets/icons/Money.svg';
import ExclamationIcon from 'assets/icons/info-circle-solid.svg';
import PaymentIcons from 'assets/icons/payment-image.svg';

import { mobile, useQuery } from 'styles/breakpoints';
import { useQuizData } from 'utils/hooks';
import { OrderSummarySubItem } from 'types/checkout';
import { Product } from 'types/product';
import { getLocalisedProduct } from 'utils/localization';

interface PaymentDialogProps {
  selectedProduct: Product | null;
  onSuccess: (data: any) => Promise<void>;
  orderSummarySubItems?: OrderSummarySubItem[];
}

const ContentContainer = styled.div`
  padding: 0 1.5rem 1.5rem 1.5rem;
  max-height: 638.656px;

  @media ${mobile} {
    padding: 0 1rem 1rem 1rem;
  }
`;

const PaymentStepsContainer = styled.div`
  max-width: 15.75rem;
  margin: 0 auto;
`;

const TitleContainer = styled.div`
  text-align: center;
  padding: 1.5rem 0;
`;

const SecureFooterStyled = styled(SecureFooter)`
  margin-top: 1rem;
  width: 100%;
`;

const EmailInputContainer = styled.div`
  padding-bottom: 0.375rem;
`;

const SecuredPaymentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
`;

const ExludeIconStyled = styled(ExcludeIcon)`
  width: 100%;
  height: 100%;
  max-height: 18px;
  max-width: 18px;
  margin-right: 0.75rem;
`;

const DolorIconStyled = styled(DolorIcon)`
  margin-right: 0.75rem;
  width: 100%;
  height: 100%;
  max-height: 18px;
  max-width: 18px;
`;

const ExclamationIconStyled = styled(ExclamationIcon)`
  margin-right: 0.75rem;
  width: 100%;
  height: 100%;
  max-height: 18px;
  max-width: 18px;
`;

const StyledPaymentIcons = styled(PaymentIcons)`
  @media ${mobile} {
    width: 11.906rem;
  }
`;
const SecureTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
`;

const SecureTextItemContainer = styled.div`
  display: flex;
  padding-top: 1.25rem;
  align-items: center;
`;

const SecureTextItemContainerLast = styled.div`
  display: flex;
  padding-top: 1.25rem;
  align-items: center;
  margin-left: 1.9rem;
`;

const PaymentDialog: FC<PaymentDialogProps & Props> = ({
  selectedProduct,
  onSuccess,
  orderSummarySubItems,
  ...props
}) => {
  const { user, subscription_config } = useSelector(
    (state: AppState) => state.user,
  );

  const data = useQuizData('payments');

  const { isMobile } = useQuery();
  const product = getLocalisedProduct(selectedProduct as Product);

  const customStyle: Styles = {
    content: {
      padding: 0,
    },
  };

  const mobileStyle: Styles = {
    content: {
      padding: 0,
      width: 'calc(100% - 2rem)',
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'calc(100% - 3rem)',
      borderRadius: 16,
    },
  };

  return (
    <Modal
      width="34.875rem"
      style={isMobile ? mobileStyle : customStyle}
      {...props}
    >
      <ContentContainer>
        <PaymentsForm onSuccess={onSuccess} />
        <SecuredPaymentContainer>
          <Text fontSize={isMobile ? 0.875 : 1}>
            GUARANTEED <strong>SAFE</strong> CHECKOUT
          </Text>
          <StyledPaymentIcons />
        </SecuredPaymentContainer>
        <SecureTextContainer>
          <SecureTextItemContainer>
            <ExludeIconStyled />
            <Text color="dark60" fontSize={isMobile ? 0.75 : 0.625}>
              Follow the instructions on the screen to complete your purchase
              securelly.
            </Text>
          </SecureTextItemContainer>
          <SecureTextItemContainer>
            <ExclamationIconStyled />
            <Text color="dark60" fontSize={isMobile ? 0.75 : 0.625}>
              Payments are charged in {`${product?.currencyId}`}. Payment
              provider fees may apply. Plan automatically renews until
              cancelled.
            </Text>
          </SecureTextItemContainer>
          <SecureTextItemContainer>
            <DolorIconStyled />
            <Text color="dark60" fontSize={isMobile ? 0.75 : 0.625}>
              {' '}
              {`${product.currencyId} ${product.finalPrice} `} will be billed
              now. After the first{' '}
              {`${product?.months}${
                product?.months === 1
                  ? (subscription_config?.billingPeriod.text2 || '').slice(
                      0,
                      -1,
                    )
                  : subscription_config?.billingPeriod.text2 || ''
              }`}
              , {`${product.currencyId} ${product.finalPrice} `} will be billed
              automatically.
            </Text>
          </SecureTextItemContainer>
          <SecureTextItemContainerLast>
            <Text color="dark60" fontSize={isMobile ? 0.75 : 0.625}>
              You may cancel your subscription at any time by dropping us an
              email at hello@brainway.app
            </Text>
          </SecureTextItemContainerLast>
        </SecureTextContainer>
      </ContentContainer>
    </Modal>
  );
};

export default PaymentDialog;
